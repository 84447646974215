/* eslint-disable @typescript-eslint/ban-types */
export interface IAPIUrls {
  managers: string;
  media: string;
  contacts: Function;
  applyNow: string;
  schools: string;
  employees: {
    search: string;
    verify: string;
    searchByFullSsn: string;
    validateSN: string;
    validatePhone: Function;
    validateAddress: string;
    isActiveEmployee: Function;
    team: Function;
    convertContact: string;
    recruitingLocation: string;
  };
  agreement: {
    terms: Function;
    pastEmployeeFormData: string;
    options: Function;
    send: Function;
    check: Function;
    currentUserAgreement: string;
    taskInfo: string;
  };
  supportContacts: string;
  tasks: {
    recruit: string;
    complete: Function;
  };
  directDeposit: string;
  profilePhoto: string;
  driverLicense: string;
  profile: {
    options: Function;
    contactInfo: Function;
    profileInfo: string;
    piiInfoAvailability: string;
    piiInfo: string;
    emergencyContacts: Function;
  };
  device: {
    requests: Function;
    status: Function;
    options: string;
  };
  apparels: {
    options: string;
    info: string;
  };
  taxDocuments: {
    options: string;
    task: string;
    w4Options: string;
    w4Info: string;
  };
  workdayAccount: {
    options: string;
    task: string;
    rehire: string;
  };
  okta: {
    refreshToken: string;
    accessToken: string;
  };
  backgroundCheck: {
    consentText: string;
    confirm: string;
    status: string;
  };
  legalTrainings: {
    lessonGroups: string;
    lesson: Function;
    quiz: Function;
    section: Function;
    question: Function;
  };
  i9: {
    info: string;
    reinitiate: string;
  };
  accounts: string;
  options: {
    state: Function
  };
  passwordPolicy: string;
  about: string;
}

export function getApiConstants(baseUrl): IAPIUrls {
  return {
    managers: baseUrl + '/api/managers',
    media: baseUrl + '/api/media/sale-introduction-video',
    contacts: (id) => baseUrl + `/api/recruiters/${id}/contacts`,
    applyNow: baseUrl + `/api/apply-now`,
    schools: baseUrl + '/api/schools',
    employees: {
      search: baseUrl + '/api/employees/search',
      verify: baseUrl + '/api/employees/verify',
      searchByFullSsn: baseUrl + '/api/employees/search-by-full-ssn',
      validateSN: baseUrl + '/api/employees/validate-sn',
      validatePhone: (phone) => baseUrl + `/api/rot/is-phone-number-valid?phoneNumber=${phone}`,
      validateAddress: baseUrl + '/api/address',
      isActiveEmployee: (employeeId) => baseUrl + `/api/employees/${employeeId}/is-account-active`,
      team: (pageNumber, size) => baseUrl + `/api/rot/team-members?pageNumber=${pageNumber}&pageSize=${size}`,
      convertContact:  baseUrl + `/api/agreements/change-agreement-signer`,
      recruitingLocation:  baseUrl + `/api/recruits/current/recruiting-location`,
    },
    agreement: {
      terms: (publicKey) => baseUrl + `/api/agreements/${publicKey}/terms`,
      pastEmployeeFormData: baseUrl + `/api/agreements/past-employee-form-data`,
      options: (publicKey) =>
        baseUrl + `/api/agreements/${publicKey}/formOptions`,
      send: (publicKey) =>
        baseUrl + `/api/agreements/${publicKey}`,
      check: (publicKey) =>
        baseUrl + `/api/agreements/${publicKey}`,
      currentUserAgreement: baseUrl + '/api/agreements/current-employee-agreement',
      taskInfo: baseUrl + '/api/onboarding-tasks/agreement'
    },
    supportContacts: baseUrl + '/api/info/supportContacts',
    tasks: {
      recruit: baseUrl + '/api/rot',
      complete: (taskId) => baseUrl + `/api/onboarding-tasks/${taskId}/complete`
    },
    directDeposit: baseUrl + `/api/onboarding-tasks/direct-deposit`,
    profilePhoto: baseUrl + '/api/rot/profilephoto',
    driverLicense: baseUrl + '/api/onboarding-tasks/driver-license',
    profile: {
      options: (id) =>
        baseUrl + `/api/recruits/${id}/formOptions`,
      contactInfo: (id) =>
        baseUrl + `/api/recruits/${id}/contact-info`,
      profileInfo: baseUrl + `/api/rot/profile`,
      piiInfoAvailability: baseUrl + `/api/recruits/current/pii-info-availability`,
      piiInfo: baseUrl + `/api/recruits/current/pii-info`,
      emergencyContacts: (id) =>
        baseUrl + `/api/recruits/${id}/emergency-contact`,
    },
    device: {
      requests: (requestId = '') =>
        baseUrl + `/api/device-requests/${requestId}`,
      status: (recruitId) =>
        baseUrl + `/api/recruits/${recruitId}/deviceRequest`,
      options: baseUrl + '/api/options/state'
    },
    apparels: {
      options: baseUrl + `/api/apparels/options`,
      info: baseUrl + `/api/apparels`
    },
    taxDocuments: {
      options: baseUrl + `/api/onboarding-tasks/w9/business-types`,
      task: baseUrl + `/api/onboarding-tasks/w9`,
      w4Options: baseUrl + `/api/onboarding-tasks/w4/marital-statuses`,
      w4Info: baseUrl + `/api/onboarding-tasks/w4`,
    },
    workdayAccount: {
      options: baseUrl + `/api/onboarding-tasks/workday-account/form-options`,
      task: baseUrl + `/api/onboarding-tasks/workday-account`,
      rehire: baseUrl + `/api/onboarding-tasks/workday-account-rehire`
    },
    okta: {
      refreshToken: baseUrl + '/api/token/refresh-token',
      accessToken: baseUrl + '/api/token/access-token'
    },
    backgroundCheck: {
      consentText: baseUrl + `/api/background-check/consent-text`,
      confirm: baseUrl + `/api/background-check/consents`,
      status: baseUrl + `/api/onboarding-tasks/background-check`
    },
    legalTrainings: {
      lessonGroups: baseUrl + `/api/onboarding-tasks/legal-trainings`,
      lesson: (lessonId) => baseUrl + `/api/trainings/all/lessons/${lessonId}`,
      quiz: (lessonId) =>
        baseUrl + `/api/trainings/all/lessons/${lessonId}/quiz`,
      section: (sectionId) =>
        baseUrl + `/api/trainings/all/sections/${sectionId}`,
      question: (questionId) =>
        baseUrl + `/api/trainings/all/questions/${questionId}`,
    },
    i9: {
      info: baseUrl + `/api/onboarding-tasks/i9`,
      reinitiate: baseUrl + `/api/onboarding-tasks/i9`,
    },
    accounts: baseUrl + `/api/accounts`,
    options: {
      state: (country) => baseUrl + '/api/options/state?country=' + country
    },
    passwordPolicy: baseUrl + '/api/password-policy',
    about: baseUrl + '/api/about'
  };
}

export const MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
  'August', 'September', 'October', 'November', 'December'
];
