import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getApiConstants, IAPIUrls } from './app/core/services/constants';
import { map, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EnvConfig } from './custom-typings';

@Injectable()
export class AppService {
  private url = '/settings.json';

  private settings: EnvConfig;

  constructor(
    protected httpClient: HttpClient
  ) {}

  public fetchSettings() {
    return this.httpClient.get(this.url).pipe(map((settings: EnvConfig) => {
      this.settings = settings;
      return settings;
    }));
  }

  public getSettings() {
    return this.settings;
  }

  public getApiUrls(): IAPIUrls {
    return getApiConstants(this.settings.serviceUrl);
  }

  private serviceAvailabilityStatus: any;

  public loadAvailabilityStatus(): Observable<any>  {
    return this.httpClient.get(this.getApiUrls().about)
    .pipe(
      tap((data) => this.serviceAvailabilityStatus = data)
    );
  }

  public getFeatureFlag(featureFlag: string): boolean {
    if (this.serviceAvailabilityStatus 
      && this.serviceAvailabilityStatus.featureFlags[featureFlag]
      && this.settings.featureFlags
      && this.settings.featureFlags.includes(featureFlag)) {
      return this.serviceAvailabilityStatus.featureFlags[featureFlag];
    } else {
      return false;
    }
  }
}
